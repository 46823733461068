/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import "./src/styles/main.scss"
import React from 'react';
import CursorProvider from './src/components/cursorprovider';

export const wrapRootElement = ({ element }) => (
  <CursorProvider>
    {element}
  </CursorProvider>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('Route has been updated!');

  const link = document.querySelector("link[rel*='icon']");
  if (link) {
    link.href = "/favicon.svg";
    link.type = "image/svg+xml";
  }

    // Add a small delay if navigating to the home page and viewport is near the top
    if (location.pathname === '/' && window.scrollY < 100) {
      // Delay video initialization
      window.sessionStorage.setItem('delayVideoInit', 'true');
    } else {
      window.sessionStorage.removeItem('delayVideoInit');
    }
};

// export const shouldUpdateScroll = ({ routerProps: { location } }) => {
//   // Check if we're navigating to the home page
//   if (location.pathname === '/') {
//     // Force a re-render of the entire page
//     window.location.reload();
//     return false;
//   }
//   return true;
// };