import React, { useState, useEffect } from 'react';
import CursorContext from './cursorcontext';

const CursorProvider = ({ children }) => {
  const [position, setPosition] = useState({left: 0, top: 0});

  useEffect(() => {
    const updatePosition = (e) => {
      setPosition({left: e.clientX, top: e.clientY});
    }
    document.addEventListener("mousemove", updatePosition);

    return () => document.removeEventListener("mousemove", updatePosition);
  }, [])

  return (
    <CursorContext.Provider value={position}>
      {children}
    </CursorContext.Provider>
  );
}

export default CursorProvider;
